<template>
  <div :class="{ 'select--invalid': error, 'select--search': isSearch }" class="select">
    <a-select
        :placeholder="placeholder"
        :show-search="showSearch"
        :value="value ? value : undefined"
        :not-found-content="null"
        :filter-option="filterOption"
        @blur="onBlur"
        @search="onSearch"
        @change="onChange">

      <a-select-option v-for="option in maxOptions"
                       :key="option.value"
                       :value="option.value">
        {{ option.text }}
      </a-select-option>
    </a-select>
    <div v-if="error" class="select__error">{{ error[0] }}</div>
  </div>
</template>
<script>
import ASelect from 'ant-design-vue/lib/select';
import {Option as ASelectOption} from 'ant-design-vue/lib/vc-select';

export default {
  name: 'VSelect',
  components: {
    ASelect,
    ASelectOption
  },
  props: {
    options: Array,
    placeholder: String,
    value: [Number, String],
    error: Array,
    isSearch: Boolean,
    showSearch: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      maxOptions: this.getOptions(this.options),
      maxCount: 200,
    }
  },
  watch: {
    options: {
      handler: function handler(val) {
        this.maxOptions = this.getOptions(val);
      },
      deep: true,
    }
  },

  methods: {
    getOptions(options) {
      if (options) {
        if (options.length <= this.maxCount) {
          return options;
        }
        return options.slice(0, this.maxCount);
      }
      return [];
    },

    onChange(value) {
      this.$emit('input', value)
    },

    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    onBlur(value) {
      this.$emit('input', value)
    },
    onSearch(value) {
      const options = this.options.filter(option => option.text.toLowerCase().indexOf(value.toLowerCase()) >= 0);
      this.maxOptions = this.getOptions(options);

      this.$emit('input', value)
    }
  },
}
</script>
<style lang="scss" scoped>
.select::v-deep {
  margin-bottom: 26px;

  .ant-select {
    width: 100%;
    height: auto;
    font-size: 15px;
    color: #1D293F;
  }

  .ant-select-selection {
    height: 50px;
    padding: 9px 14px;
  }

  .ant-select-selection__placeholder {
    color: #9ea3a7;
  }

  .select__error {
    display: none;
    opacity: 0;
    font-size: 13px;
    line-height: 17px;
    color: #F2323F;
    transition: all 200ms ease;
  }
}

.select--invalid::v-deep {
  .ant-select-selection {
    margin-bottom: 8px;
    background-color: #FFEAEA;
    border-color: #F2323F;
  }

  .select__error {
    display: block;
    opacity: 1;
  }
}

.select--search::v-deep {
  .ant-select {
    margin-bottom: 0;
  }

  .ant-select-selection {
    height: 40px;
    padding: 4px 14px;
    border: unset;
    background-color: #F4F7FC;
    color: #9C9EA3;
  }

  .ant-select-selection__placeholder {
    color: #9C9EA3;
  }
}
</style>
