<template>
  <modal @close="close">
    <template v-slot:title>
      Редактирование пользователя
    </template>
    <template v-slot:body>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <v-input
            v-model="surname"
            :error="errors.surname"
            placeholder="Фамилия"
            autofocus
            required
          />
        </div>
        <div class="modal__form-col">
          <v-input
            v-model="name"
            :error="errors.name"
            placeholder="Имя"
            required
          />
        </div>
        <div class="modal__form-col">
          <v-input
            v-model="patronymic"
            :error="errors.patronymic"
            placeholder="Отчество"
            required
          />
        </div>
      </div>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <v-input
            v-model="login"
            :error="errors.login"
            placeholder="Логин"
            required
          />
        </div>
        <div class="modal__form-col">
          <v-input
            v-model="phone"
            :error="errors.phone"
            placeholder="Телефон"
            required
          />
        </div>
        <div class="modal__form-col">
          <v-select
            v-model="role"
            :options="roles"
            placeholder="Роль"
            required
          />
        </div>
      </div>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <v-input
            v-model="password"
            placeholder="Пароль"
            required
          />
        </div>
        <div class="modal__form-col">
          <v-input
            v-model="password_confirmation"
            placeholder="Подтверждение пароля"
            required
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <v-button type="submit" @click.native="close" class="gray">
        Отмена
      </v-button>
      <v-button type="submit" @click.native="save">
        Сохранить
      </v-button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/ui/Modal";
import VButton from "@/components/ui/VButton";
import VInput from "@/components/ui/VInput";
import VSelect from "@/components/ui/VSelect";
import {update as updateUser} from "@/api/user/update";

export default {
  props: {
    row: Object,
  },
  data() {
    return {
      surname: this.row.surname,
      name: this.row.name,
      patronymic: this.row.patronymic,
      phone: this.row.phone,
      login: this.row.login,
      role: this.row.role.value,
      password: null,
      password_confirmation: null,
      roles: [
        {value: 'admin', text: 'Администратор'},
        {value: 'user', text: 'Оператор'},
      ],
      errors: {},
    }
  },
  components: {
    Modal,
    VButton,
    VInput,
    VSelect
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.errors = {};

      let data = {
        surname: this.surname,
        name: this.name,
        patronymic: this.patronymic,
        phone: this.phone,
        login: this.login,
        role: this.role,
      };
      if (this.password) {
        data.password = this.password;
      }
      if (this.password_confirmation) {
        data.password_confirmation = this.password_confirmation;
      }

      updateUser(this.row.id, data)
        .then(() => {
          this.getNotify('success', 'Изменено');
          this.$parent.loadItems();
          this.close();
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        })
    },
  }
}
</script>
