<template>
  <div>
    <Title>
      Пользователи
      <PlusButton @click.native="create = true">Добавить пользователя</PlusButton>
    </Title>

    <Create v-if="create" @close="create = false"/>

    <Edit v-if="edit" :row="row" @close="edit = false"/>

    <Delete v-if="del" :row="row" @close="del = false"/>

    <vue-good-table
      ref="vueTable"
      :columns="columns"
      :isLoading.sync="isLoading"
      :pagination-options="paginationOptions"
      :rows="rows"
      :totalRows="totalRecords"
      mode="remote"
      styleClass="primary-table"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template slot="column-filter" slot-scope="{column, updateFilters }">
        <the-mask
          v-if="column.filterOptions.inputType === 'phone'"
          v-model="serverParams.columnFilters.phone"
          type="text"
          class="vgt-input"
          :placeholder="column.filterOptions.placeholder"
          @keyup.enter.native="updateFilters(column, serverParams.columnFilters.phone)"
          mask="+7 (###) ### ##-##"
        />
      </template>
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'full_name'" class="relative">
          {{ getFullName(props.row) }}
        </div>
        <div v-else-if="props.column.field === 'role'" class="relative">
          {{ props.formattedRow[props.column.field] }}
          <a-dropdown class="primary-table__actions-dropdown">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="more"/>
            </a>
            <a-menu slot="overlay" class="primary-table__dropdown-menu">
              <a-menu-item>
                <a @click="onEdit(props.row)">
                  <a-icon type="edit"/>
                  Изменить
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="onDelete(props.row)">
                  <a-icon type="delete"/>
                  Удалить
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <Pagination
          :total="props.total"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        />
      </template>
      <div slot="emptystate" class="text-center">
        Нет данных
      </div>
      <template slot="loadingContent">
        <span class="vgt-loading__content">
            Загрузка...
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import Vue from 'vue';
import {vueTable} from "@/mixins/vue-table";
import Create from "./modal/Create"
import Edit from "./modal/Edit"
import Delete from "./modal/Delete"
import {getAll} from "@/api/user/getAll"
import Title from '@/components/ui/Title';
import PlusButton from '@/components/ui/PlusButton';
import ADropdown from 'ant-design-vue/lib/dropdown';
import AMenu from 'ant-design-vue/lib/menu';
import AIcon from 'ant-design-vue/lib/icon';
import Pagination from '@/components/ui/Pagination';

Vue.use(AMenu);

export default {
  mixins: [vueTable],
  components: {
    Create,
    Edit,
    Delete,
    Title,
    PlusButton,
    ADropdown,
    AIcon,
    Pagination
  },
  data() {
    return {
      getAll,
    }
  },
  computed: {
    columns() {
      return [
        {
          field: 'id',
          label: 'ID',
          sortable: true,
          width: '200px',
          tdClass: 'fold',
          filterOptions: {
            customFilter: true,
            enabled: true,
            placeholder: 'ID',
            trigger: 'enter',
            inputType: 'text',
          },
        },
        {
          field: 'full_name',
          label: 'ФИО',
          sortable: true,
          width: '33.3%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'ФИО',
            trigger: 'enter',
            inputType: 'text',
          },
        },
        {
          field: 'phone',
          label: 'Телефон',
          sortable: true,
          width: '33.3%',
          tdClass: 'fold',
          formatFn: value => {
            return this.$options.filters.phoneNumber(value);
          },
          filterOptions: {
            enabled: true,
            placeholder: 'Телефон',
            trigger: 'enter',
            inputType: 'phone',
          },
        },
        {
          field: 'role',
          formatFn: obj => {
            return obj.label;
          },
          label: 'Роль',
          sortable: true,
          width: '33.3%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Роль',
            trigger: 'enter',
            filterDropdownItems: [
              {value: 'admin', text: 'Администратор'},
              {value: 'user', text: 'Оператор'},
            ],
            inputType: 'select',
          },
        },
      ]
    }
  },
  methods: {
    getFullName(row) {
      return (row.surname || '') + ' ' + (row.name || '') + ' ' + (row.patronymic || '');
    },
  },
}
</script>

<style lang="scss">
</style>
