<template>
  <button class="plus-button inline-flex items-center" type="button">
    <svg class="stroke-current mr-2" height="10" viewBox="0 0 10 10" width="10">
      <line stroke-width="1.5" x1="0" x2="10" y1="5" y2="5"></line>
      <line stroke-width="1.5" x1="5" x2="5" y1="0" y2="10"></line>
    </svg>
    <div>
      <slot></slot>
    </div>
  </button>
</template>
<script>
export default {
  name: "PlusButton"
}
</script>

<style lang="scss" scoped>
.plus-button {
  background: #00ADFF;
  padding: 7px 24px;
  border-radius: 4px;
  color: #FFF;
  transition: all .2s ease-in-out;
  min-width: 129px;
  display: flex;
  justify-content: center;
  outline: none;
  font-size: 15px;
  height: 32px;

  &:hover {
    background: #01a0ea;
  }

  div {
    margin-top: -3px;
  }
}
</style>