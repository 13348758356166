<template>
  <div class="modal">
    <modal @close="close">
      <template v-slot:title>
        Создание пользователя
      </template>
      <template v-slot:body>
        <div class="modal__form-row">
          <div class="modal__form-col">
            <v-input
              v-model="surname"
              :error="errors.surname"
              placeholder="Фамилия"
              autofocus
              required
            />
          </div>
          <div class="modal__form-col">
            <v-input
              v-model="name"
              :error="errors.name"
              placeholder="Имя"
              required
            />
          </div>
          <div class="modal__form-col">
            <v-input
              v-model="patronymic"
              :error="errors.patronymic"
              placeholder="Отчество"
              required
            />
          </div>
        </div>
        <div class="modal__form-row">
          <div class="modal__form-col">
            <v-input
              v-model="login"
              :error="errors.login"
              placeholder="Логин"
              required
            />
          </div>
          <div class="modal__form-col">
            <v-input
              v-model="phone"
              :error="errors.phone"
              placeholder="Телефон"
              required
            />
          </div>
          <div class="modal__form-col">
            <v-select
              v-model="role"
              :options="roles"
              :error="errors.role"
              placeholder="Роль"
              required
            />
          </div>
        </div>
        <div class="modal__form-row">
          <div class="modal__form-col">
            <v-input
              v-model="password"
              :error="errors.password"
              placeholder="Пароль"
              required
            />
          </div>
          <div class="modal__form-col">
            <v-input
              v-model="password_confirmation"
              :error="errors.password_confirmation"
              placeholder="Подтверждение пароля"
              required
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <v-button type="submit" @click.native="close" class="gray">
          Отмена
        </v-button>
        <v-button type="submit" @click.native="save">
          Сохранить
        </v-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { create as createUser } from "@/api/user/create";
import Modal from "@/components/ui/Modal";
import VButton from "@/components/ui/VButton";
import VInput from "@/components/ui/VInput";
import VSelect from "@/components/ui/VSelect";

export default {
  data() {
    return {
      surname: null,
      name: null,
      patronymic: null,
      phone: null,
      login: null,
      role: null,
      password: null,
      password_confirmation: null,
      roles: [
        {value: 'admin', text: 'Администратор'},
        {value: 'user', text: 'Оператор'},
      ],
      errors: {},
    }
  },
  components: {
    Modal,
    VButton,
    VInput,
    VSelect
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.errors = {};
      createUser({
        surname: this.surname,
        name: this.name,
        patronymic: this.patronymic,
        phone: this.phone,
        login: this.login,
        role: this.role,
        password: this.password,
        password_confirmation: this.password_confirmation,
      })
      .then(() => {
        this.$parent.loadItems();
        this.close();
      })
      .catch(error => {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.modal::v-deep {
  .modal__wrapper {
    max-width: 836px;
    width: 100%;
  }
}
</style>
